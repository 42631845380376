<template>
  <span class="member-level">
    <template v-if="member.mention">
      <span class="dash">&dash;</span>
      <span class="mention mr-2" v-html="member.mention"></span>
    </template>
    <span
      class="tag level-text"
      :data-level="member.level"
      :title="member.rating ? 'Notre globale: '+member.rating : ''"
      v-if="member.level"
    >{{member.level}}</span>
  </span>
</template>

<script>
export default {
  props: ["member"],
};
</script>

<style scoped lang="scss">
.member-level {
  display: block;
  margin-top: -0.6em;
  @media screen and (min-width: 500px) {
    margin-top: 0;
    display: inline-block;
  }
  .dash {
    padding: 0 0.5em 0 0;
    display: none;
    @media screen and (min-width: 500px) {
      display: inline;
    }
  }
  vertical-align: top;
  .level-text {
    position: relative;
    z-index: 1;
    overflow: hidden;
    border: 1px solid #333;
    font-size: 60%;
    &:before {
      background: #ffffff;
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: -1;
      opacity: 1;
    }
  }

  .mention {
    font-size: smaller;
    /* background: white;
  border: 1px solid #d7d3d3; */
  }
}
</style>